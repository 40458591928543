import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button} from 'reactstrap'

import BtuQuestion from './question'

import termsAndConditionsEnPdf from '../../assets/pdf/General Conditions Publishers.pdf'
import termsAndConditionsFrPdf from '../../assets/pdf/Conditions Generales Editeurs.pdf'

class BtuWidgetFormLeftPanel extends Component {
  render() {
    const {
      t,
      userLocale,
      displayErrors,
      email,
      emailError,
      wallet,
      walletError,
      width,
      height,
      // color,
      cgv,
      onChangeEmail,
      onChangeWallet,
      onChangeWidth,
      onChangeHeight,
      // onChangeColor,
      onChangeCgv,
      onGenerateWidget,
    } = this.props

    const pdfFile = (userLocale && userLocale.startsWith('fr') ? termsAndConditionsFrPdf : termsAndConditionsEnPdf)
    const pdfCreateAddress = ( userLocale && userLocale.startsWith('fr')
      ? "https://btu-protocol.com/pdf/Obtenir_%20Adresse_BTU_Android_fr.pdf"
      : "https://btu-protocol.com/pdf/Get%20BTU%20Address%20on%20iOS%20(EN).pdf")

    return (
      <div className="widget_left">
        <div className="steps pt-5">
            <div className="form_section">
              <label htmlFor="widget_email">
                {t('configuration.form.email.label')}
              </label>
              <input
                type="text"
                className={"full_width" + (displayErrors && emailError ? ' widget_field_error' : '')}
                name="widget_email"
                id="widget_email"
                placeholder={t('configuration.form.email.placeholder')}
                value={email}
                onChange={e => onChangeEmail(e.target.value)}
              />
              { displayErrors && emailError
                && (
                  <div className="error_text">
                    {emailError}
                  </div>
                )
              }
            </div>
            <div className="form_section">
              <div className="label_with_question">
                <label htmlFor="widget_wallet">
                  {t('configuration.form.wallet.label')}
                  {' '}
                  {'('}
                  <a
                    href={pdfCreateAddress}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{fontSize: '13px', textTransform: 'none', textDecoration: 'underline'}}
                  >
                    
                    {t('configuration.form.wallet.create')}
                  </a>
                  {')'}
                </label>
                <BtuQuestion
                  text={t('configuration.form.wallet.tooltip')}
                  alt={t('global.help')}
                />
              </div>
              <input
                type="text"
                className="full_width"
                name="widget_wallet"
                id="widget_wallet"
                placeholder={t('configuration.form.wallet.placeholder')}
                value={wallet}
                onChange={e => onChangeWallet(e.target.value)}
              />
              { displayErrors && walletError
                && (
                  <div className="error_text">
                    {walletError}
                  </div>
                )
              }
            </div>
            <div className="form_section">
              <label htmlFor="widget_height">
                {t('configuration.form.size.label')}
              </label>
              <div className="size_fields_container">
                <div className="size_field_wrapper">
                  <input
                    type="text"
                    className="size_field"
                    name="widget_width"
                    id="widget_width"
                    title={t('configuration.form.size.width')}
                    placeholder={t('configuration.form.size.placeholder_width')}
                    value={width}
                    onChange={e => onChangeWidth(e.target.value)}
                  />
                  <span className="size_field_px">{t('configuration.form.size.px')}</span>
                </div>
                <div className="size_fields_separator">×</div>
                <div className="size_field_wrapper">
                  <input
                    type="text"
                    className="size_field"
                    name="widget_height"
                    id="widget_height"
                    title={t('configuration.form.size.height')}
                    placeholder={t('configuration.form.size.placeholder_height')}
                    value={height}
                    onChange={e => onChangeHeight(e.target.value)}
                  />
                  <span className="size_field_px">{t('configuration.form.size.px')}</span>
                </div>
              </div>
            </div>
            {/*
            <div className="form_section">
              <label htmlFor="widget_color">
                {t('configuration.form.color.label')}
              </label>
              <div className="color_field_container">
                <div className="color_field_wrapper">
                  <span className="color_field_hex">{t('configuration.form.color.hex')}</span>
                  <input
                    type="text"
                    className="color_field"
                    name="widget_color"
                    id="widget_color"
                    placeholder=""
                    value={color}
                    onChange={e => onChangeColor(e.target.value)}
                  />
                </div>
                <div className="color_field_show" style={{ backgroundColor: color }}>
                </div>
              </div>
            </div>
            */}
            <div className="form_section terms_and_conditions">
              <div className="terms_and_conditions_checkbox">
                <input type="checkbox" id="terms_and_conditions" onChange={(e) => onChangeCgv(e.target.checked)} checked={cgv} />
              </div>
              <div>
                <label htmlFor="terms_and_conditions">
                  <span className={displayErrors && !cgv ? 'error_text' : ''}>{t('configuration.form.terms_and_conditions.text')}</span>
                  {' '}
                  <a href={pdfFile} target="_blank" rel="noopener noreferrer">{t('configuration.form.terms_and_conditions.link')}</a>
                </label>
              </div>
            </div>
            <div className="form_section">
              <Button
                className={'btn btn-primary d-inline-flex align-items-center justify-content-between'}
                style={{ lineHeight: '1em', backgroundColor: '#0ca678' }}
                onClick={onGenerateWidget}
              >
                {t('configuration.form.button')}
              </Button>
            </div>
          </div>
      </div>
    )
  }
}

BtuWidgetFormLeftPanel.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
  userLocale: PropTypes.string,
  displayErrors: PropTypes.bool,
  email: PropTypes.string,
  emailError: PropTypes.string,
  wallet: PropTypes.string,
  walletError: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  cgv: PropTypes.bool,
  onChangeEmail: PropTypes.func,
  onChangeWallet: PropTypes.func,
  onChangeWidth: PropTypes.func,
  onChangeHeight: PropTypes.func,
  onChangeColor: PropTypes.func,
  onChangeCgv: PropTypes.func,
  onGenerateWidget: PropTypes.func,
}

BtuWidgetFormLeftPanel.defaultProps = {
  userLocale: null,
  selectedWidget: 0,
  displayErrors: false,
  email: null,
  emailError: null,
  wallet: null,
  walletError: null,
  width: 500,
  height: 500,
  color: '#000000',
  cgv: false,
  onChangeEmail: () => null,
  onChangeWallet: () => null,
  onChangeWidth: () => null,
  onChangeHeight: () => null,
  onChangeColor: () => null,
  onChangeCgv: () => null,
  onGenerateWidget: () => null,
}

export default BtuWidgetFormLeftPanel
