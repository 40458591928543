import React, {Component} from 'react'
import PropTypes from 'prop-types'

import BedIcon from '../../assets/icons/sleeping-bed-silhouette.svg'
import BedIconSelected from '../../assets/icons/sleeping-bed-silhouette-white.svg'
import TicketIcon from '../../assets/icons/ticket.svg'
import TicketIconSelected from '../../assets/icons/ticket-white.svg'
import CarIcon from '../../assets/icons/car.svg'
import CarIconSelected from '../../assets/icons/car-white.svg'

class BtuWidgetFormChooser extends Component {

  render() {
    const {
      t,
      selected,
      onClick,
    } = this.props

    const images = [
      {
        selected: BedIconSelected,
        unselected: BedIcon,
      },
      {
        selected: TicketIconSelected,
        unselected: TicketIcon,
      },
      {
        selected: CarIconSelected,
        unselected: CarIcon
      }
    ]

    return (
      <div className="form_chooser_container">
        {t('configuration.chooser', { returnObjects: true }).map((item, index) => (
          <div
            className={(index === selected ? 'selected' : '')}
            onClick={e => onClick(index)}
            key={item.title}
          >
            { index < images.length
              && (
                <img
                  src={selected === index
                    ? images[index].selected 
                    : images[index].unselected
                  }
                  alt={item.title}
                />
              )
            }
            <div className="form_chooser_text">
              <span className="form_chooser_title">{item.title}</span>
              <span className="form_chooser_commission">{item.commission}</span>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

BtuWidgetFormChooser.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
  selectedWidget: PropTypes.number,
  onClick: PropTypes.func,
}

BtuWidgetFormChooser.defaultProps = {
  selectedWidget: 0,
  onClick: () => null,
}

export default BtuWidgetFormChooser
