import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'

import './catalogue.css'

import ArrowDown from '../../components/arrowDown/arrowDown.jsx'
class BtuCatalogue extends Component {
  render() {
    const {
      t,
      onClickCatalogue,
    } = this.props
    
    // Déclare quelles cellules du carrousel sont désactivées
    const cellDisabled = [
      false, // Tours & Activities
      false, // Hotels
      false, // Car rental
    ]

    const cellKeys = [
      'cell0',
      'cell1',
    ]

    return (
      <section id="catalogue">
        <Container fluid>
          <Row noGutters>
            <Col className='order-2 order-lg-1 p-0' lg={7}>
              <div className="catalogue-carousel">
                {t('catalogue.cells', { returnObjects: true }).map((cell, index) => (
                  <div className="catalogue-cell" key={cellKeys[index]}>
                  <div className="catalogue_widget_box">
                    <div className="catalogue_cell_title">
                      <h1>{cell.title}</h1>
                    </div>
                    <p className="catalogue_cell_text">{cell.text}</p>
                    <p className="catalogue_cell_commission">{cell.commission}</p>
                    <div className="catalogue_cell_button_wrapper">
                      <span
                        className="catalogue_cell_button"
                        onClick={(e) => { console.log(index); onClickCatalogue(index)}}
                      >
                        {t('catalogue.button')}
                      </span>
                    </div>
                    { cellDisabled[index]
                      && (
                        <React.Fragment>
                          <div className="catalogue_cell_disabled_opacity" />
                          <div className="catalogue_cell_disabled_banner">
                            {t('catalogue.coming_soon')}
                          </div>
                        </React.Fragment>
                      )
                    }
                  </div>
                </div>
                ))}
              </div>
            </Col>
            <Col className={'order-1 order-lg-2 p-0'} lg={5}>
              <div className='d-flex flex-column align-items-center align-items-xl-end justify-content-center catalogue-action'>
                <h1 className={'section-title text-center text-lg-right'}>
                  {t('catalogue.integrate')}
                </h1>
                <button className={'btn btn-primary btn-lg btn-get-started font-weight-bold'} data-target={'#configuration'}>
                  {t('global.get_started')}
                </button>
              </div>
            </Col>
          </Row>
          <ArrowDown
          ancreValue = '2' 
          />
        </Container>
      </section>
    )
  }
}

BtuCatalogue.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
  onClickCatalogue: PropTypes.func,
}

BtuCatalogue.defaultProps = {
  onClickCatalogue: () => null,
}

export default BtuCatalogue
