const constants = {
  url: {
    facebook: 'https://www.facebook.com/btuprotocol/',
    twitter: 'https://twitter.com/BtuProtocol',
    linkedIn: 'https://www.linkedin.com/company/btu-protocol/',
    telegram: 'https://t.me/btucommunity',
    medium: 'https://medium.com/@BTUProtocolTeam/latest',
    github: 'https://github.com/btuprotocol/BTU.js',
    youtube: 'https://www.youtube.com/channel/UC4TU0cH82u0kLeEomf26Z0g',
    instagram: 'https://www.instagram.com/btuprotocol/',
    btuProtocol: 'https://btu-protocol.com/',
    btuProtocolAbout: 'https://btu-protocol.com/about',
    btuProtocolBuyBtu: 'https://btu-protocol.com/buybtu',
    mail: 'info@btu-protocol.com',
    btuTours: 'https://btu-tours.com',
    btuHotels: 'https://hotel.travel-everywhere.com',
    carRental: 'https://carrental.travel-everywhere.com',
    exporterApi: 'https://claim-api.btu-direct.com/exporter'
  },
  sendmail: {
    url: 'https://btu-protocol.com/srv/',
    script: 'contact.php',
  }
}

export default constants
