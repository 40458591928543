import React, {Component} from 'react'
import {connect} from "react-redux"

import BtuIntro from './intro/intro'
import BtuBenefits from './benefits/benefits'
import BtuCatalogue from './catalogue/catalogue'
//import BtuHow from './how/how'
import BtuReviews from './reviews/reviews'
import BtuWidgetForm from './widgetForm/widgetForm'
import BtuFaq from './faq/faq'
import BtuContact from './contact/contact'

import $ from 'jquery'
import 'slick-carousel'
import WOW from 'wow.js'
import {sendMail, textChange} from "../actions/actionCreators"

import {withTranslation} from "react-i18next"

import {  css } from '@emotion/core'

import BtuHeader from "./_common/BtuHeader"
import BtuFooter from "./_common/BtuFooter"

import {FORM_CONTACT} from '../utils/constants'


const loaderStyle = css`
    display: block
    margin: 0 0 0 10px
    padding: 0
    border-color: red
`

class BtuHome extends Component {
  state = {
    currentWidget: 0,
  }

  componentDidMount() {
    // Reviews carousel
    $('.review-carousel').slick({
      autoplay: true,
      infinite: true,
      loop: true,
      autoplaySpeed: 15000,
      pauseOnFocus: false,
      pauseOnHover: false,
      touchMove: false,
      draggable: false,
      dots: true,
      fade: true,
      nextArrow: '<button type="button" class="slick-next"><i class="la la-long-arrow-right la-3x text-dark"></i></button>',
      prevArrow: '<button type="button" class="slick-prev"><i class="la la-long-arrow-left la-3x text-dark"></i></button>',
      responsive: [
        {
          breakpoint: 595,
          settings: {
            arrows: false
          },
        }
      ]
    })

    // Catalogue carousel
    $('.catalogue-carousel').slick({
      autoplay: true,
      infinite: true,
      dots: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      touchMove: false,
      draggable: false,
      arrows: true,
      nextArrow: '<button type="button" class="slick-next"><i class="la la-long-arrow-right la-3x text-dark"></i></button>',
      prevArrow: '<button type="button" class="slick-prev"><i class="la la-long-arrow-left la-3x text-dark"></i></button>',
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1740,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            fade: true,
          },
        },
      ],
    })

    // WOW animations initialization
    new WOW().init()

    // Handle back to top button click
    $('.btn-scroll-top').on('click', function () {
      $('body,html').animate({scrollTop: 0}, 800)
    })

    if(window.innerWidth < 1192) {
      // Mobile Screen
      $('nav').removeClass('bg-white')
      $('nav').fadeIn().addClass('bg-light')
    }

    // Scroll header and toggle fixed position
    $(window).scroll(function () {
      let scrolledHeight = $(this).scrollTop()

      // Display back to top button
      if (scrolledHeight >= 100) {
          $('.btn-scroll-top').fadeIn(900).removeClass('d-none')
      } else if (!$('.btn-scroll-top').hasClass('d-none')) {
          $('.btn-scroll-top').fadeOut(900).addClass('d-none')
      }

      // Toggle fixed position header section
      if(window.innerWidth >= 1192) {
          // Desktop Screen
          if (scrolledHeight >= 50) {
              $('nav').removeClass('bg-white')
              $('nav').fadeIn().addClass('bg-light')
          } else {
              $('nav').removeClass('bg-light')
              $('nav').fadeIn().addClass('bg-white')
          }
      }
      else
      {
          $('nav').removeClass('bg-white')
          $('nav').fadeIn().addClass('bg-light')
      }
    })

    document.addEventListener('mousewheel', (event) => { event.preventDefault() }, { passive: true })
    document.addEventListener('touchstart', (event) => { event.preventDefault() }, { passive: true })
    document.addEventListener('touchmove', (event) => { event.preventDefault() }, { passive: true })
    document.addEventListener('wheel', (event) => { event.preventDefault() }, { passive: true })
  }

  handleChange = (e) => {
    let {dispatch} = this.props
    dispatch(textChange(FORM_CONTACT, e.target.name, e.target.value))
  }

  onClickCatalogue = (currentWidget) => {
    this.setState({ currentWidget }, () => {
      document.getElementById('configuration').scrollIntoView()
    })
  }

  onChangeWidgetType = (currentWidget) => {
    this.setState({ currentWidget })
  }

  render() {
    const {
      t,
      dispatch,
      userLocale,
      isLoading,
      formContact,
    } = this.props

    return (
      <React.Fragment>
        <BtuHeader useSuspense={false} />

        <BtuIntro t={t} />
        <BtuBenefits t={t} userLocale={userLocale} />
        
        <BtuCatalogue
          t={t}
          userLocale={userLocale}
          onClickCatalogue={this.onClickCatalogue}
        />
        
        <BtuWidgetForm
          t={t}
          userLocale={userLocale}
          selectedWidget={this.state.currentWidget}
          onChangeWidgetType={this.onChangeWidgetType}
        />
        <BtuFaq t={t} />
        <BtuReviews t={t} />
        <BtuContact
          t={t}
          isLoading={isLoading}
          formContact={formContact}
          handleChange={this.handleChange}
          dispatch={dispatch}
          sendMail={sendMail}
          loaderStyle={loaderStyle}
        />

        <BtuFooter
          t={t}
          useSuspense={false}
        />
        <div className="btn-scroll-top d-none text-decoration-none">
          <i className="la la-long-arrow-up la-2x"></i>
        </div>
      </React.Fragment>
    )
  }
}

BtuHome.propTypes = {}

BtuHome.defaultProps = {}

const mapStateToProps = (state) => {
  return {
    userLocale: state.btu.userLocale,
    isOpenModal: state.btu.isOpenModal,
    modalAction: state.btu.modalAction,
    isLoading: state.btu.isLoading,
    formContact: state.btu[FORM_CONTACT],
  }
}

BtuHome = connect(mapStateToProps, null)(BtuHome)
export default withTranslation()(BtuHome)