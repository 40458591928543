import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import request from 'superagent'

import BtuWidgetFormChooser from './widgetFormChooser'
import BtuWidgetFormLeftPanel from './widgetFormLeftPanel'
import BtuWidgetFormRightPanel from './widgetFormRightPanel'
/*
import BtuWidgetFormWalletNotDetected from './widgetFormWalletNotDetected'
import BtuWidgetFormGettingWallet from './widgetFormGettingWallet'
import BtuWidgetFormChangeWallet from './widgetFormChangeWallet'
*/

import constants from '../../config/constants.js'

import './widgetForm.css'

class BtuWidgetForm extends Component {
  state = {
    displayErrors: false,
    email: '',
    emailError: '',
    wallet: '',
    walletError: '',
    width: 800,
    height: 500,
    color: '#000000',
    cgv: false,
    code: '',
    link: '',
    isFlipped: false,
  }

  checkEmail = (email) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(String(email).toLowerCase())
  }

  onChangeEmail = (email) => {
    const {
      t,
    } = this.props

    const {
      isFlipped
    } = this.state

    if (isFlipped) {
      this.flipRightPanel(true)
    }

    let emailError = ''
    if (!this.checkEmail(email)) {
      emailError=t('configuration.form.email.error')
    }

    this.setState({
      email,
      emailError,
    })
  }

  checkWallet = (wallet) => {
    const pattern = new RegExp('^0[xX][0-9A-Fa-f]{40}$')
    return pattern.test(wallet)
  }

  onChangeWallet = (wallet) => {
    const {
      t,
    } = this.props

    const {
      isFlipped
    } = this.state

    if (isFlipped) {
      this.flipRightPanel(true)
    }

    let walletError = ''
    if (!this.checkWallet(wallet)) {
      walletError=t('configuration.form.wallet.error')
    }

    this.setState({
      wallet,
      walletError,
    })
  }

  onChangeWidth = (width) => {
    const {
      isFlipped
    } = this.state

    if (isFlipped) {
      this.flipRightPanel(true)
    }

    if (!Number.isNaN(+width)) {
      this.setState({ width })
    }
  }

  onChangeHeight = (height) => {
    const {
      isFlipped
    } = this.state

    if (isFlipped) {
      this.flipRightPanel(true)
    }

    if (!Number.isNaN(+height)) {
      this.setState({ height })
    }
  }

  onChangeColor = (color) => {
    const {
      isFlipped
    } = this.state

    if (isFlipped) {
      this.flipRightPanel(true)
    }

    this.setState({ color })
  }

  onChangeCgv = (cgv) => {
    const {
      isFlipped
    } = this.state

    if (isFlipped) {
      this.flipRightPanel(true)
    }

    this.setState({ cgv })
  }

  /**
   * Appelée au changement de type de widget
   *
   * @param {number} currentWidget - Indice du widget à afficher
   */
  onChangeWidgetType = (currentWidget) => {
    const {
      onChangeWidgetType,
    } = this.props

    const {
      isFlipped
    } = this.state

    if (isFlipped) {
      this.flipRightPanel(true)
    }

    onChangeWidgetType(currentWidget)
  }

  /**
   * Clic sur le bouton "Générer le widget"
   */
  onGenerateWidget = () => {
    const {
      t,
      userLocale,
      selectedWidget
    } = this.props

    const {
      email,
      wallet,
      width,
      height,
      // color,
      cgv,
    } = this.state

    const language = (userLocale && userLocale.substring(0,2) === 'fr' ? 'fr' : 'en')

    if (this.checkEmail(email) && this.checkWallet(wallet) && cgv) {
      let code = ''
      let link = ''

      switch (selectedWidget) {
        case 0:
          link = constants.url.btuHotels + '/?w=' + wallet
          code = '<iframe name="BTUProtocol" frameborder="0" scrolling="yes" style="border: none; max-width: 100%;" width="' + width + 'px" height="' + height + 'px" src="' + link + '"></iframe>'
          break
        case 1:
          link = constants.url.carRental + '/?w=' + wallet
          code = '<iframe name="BTUProtocol" frameborder="0" scrolling="yes" style="border: none; max-width: 100%;" width="' + width + 'px" height="' + height + 'px" src="' + link + '"></iframe>'
          break
        default:
      }

      this.flipRightPanel(false)

      this.setState({
        code: code,
        link: link,
        displayErrors: true,
      })

      this.exportUser(language, code, link)

    } else {
      console.log("NPOOOOOOOOO")
      this.setState({
        code: '',
        link: '',
        emailError: this.checkEmail(email) ? '' : t('configuration.form.email.error'),
        walletError: this.checkWallet(wallet) ? '' : t('configuration.form.wallet.error'),
        displayErrors: true,
      })
    }
  }

  /**
   * Exporte un user lors de la génération du widget
   */
  exportUser = (language, code, link) => {
    const {
      wallet,
      email,
    //  code,
    //  link,
    } = this.state

    const body = {
      walletAddress: wallet,
      email: email,
      from: 'btu-earn',
      code: code,
      link: link,
      userFrom: 'btu-earn',
      userlang: language,
    }

    request.post(constants.url.exporterApi).send(body)
    .then((res) => {
      console.log('user saved')
    }).catch((err) => {
      console.log('backend error')
    })
  }

  /**
   * Retourne les éléments du panneau de droite
   *
   * @param {bool} [displayWidget=true] - true s'il faut afficher le widget, false sinon
   */
  flipRightPanel = (displayWidget=true) => {
    const front = document.getElementsByClassName('widget_right_front')
    const back = document.getElementsByClassName('widget_right_back')

    if (front && front.length > 0 && back && back.length > 0) {
      if (displayWidget) {
        front[0].classList.remove('widget_right_front_flipped')
        back[0].classList.remove('widget_right_back_flipped')
      } else {
        front[0].classList.add('widget_right_front_flipped')
        back[0].classList.add('widget_right_back_flipped')
      }

      this.setState({ isFlipped: !displayWidget })
    }
  }

  render() {
    const {
      t,
      userLocale,
      selectedWidget,
    } = this.props

    const {
      displayErrors,
      email,
      emailError,
      wallet,
      walletError,
      width,
      height,
      color,
      cgv,
      code,
      link,
      isFlipped,
    } = this.state

    const language = (userLocale && userLocale.substring(0,2) === 'fr' ? 'fr' : 'en')

    return (
      <section id="configuration">
        <Container fluid>
          <Row>
            <Col className="configuration-col">
              <Card>
                <CardBody>
                  <div className="configuration-title">
                    <h1 className={'section-title'}>
                      {t('configuration.title')}
                    </h1>
                    <BtuWidgetFormChooser
                      t={t}
                      selected={selectedWidget}
                      onClick={this.onChangeWidgetType}
                    />
                  </div>
                  {/*
                  <BtuWidgetFormWalletNotDetected
                    t={t}
                  />
                  */}
                  {/*
                  <BtuWidgetFormGettingWallet
                    t={t}
                  />
                  */}
                  {/*
                  <BtuWidgetFormChangeWallet
                    t={t}
                  />
                  */}
                  <div className="widget_body">
                    <BtuWidgetFormLeftPanel
                      t={t}
                      userLocale={userLocale}
                      displayErrors={displayErrors}
                      email={email}
                      emailError={emailError}
                      wallet={wallet}
                      walletError={walletError}
                      width={width}
                      height={height}
                      color={color}
                      cgv={cgv}
                      onChangeEmail={this.onChangeEmail}
                      onChangeWallet={this.onChangeWallet}
                      onChangeWidth={this.onChangeWidth}
                      onChangeHeight={this.onChangeHeight}
                      onChangeColor={this.onChangeColor}
                      onChangeCgv={this.onChangeCgv}
                      onGenerateWidget={this.onGenerateWidget}
                    />
                    <BtuWidgetFormRightPanel
                      t={t}
                      userLocale={userLocale}
                      width={width}
                      height={height}
                      selected={selectedWidget}
                      language={language}
                      code={code}
                      link={link}
                      isFlipped={isFlipped}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

BtuWidgetForm.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
  userLocale: PropTypes.string,
  selectedWidget: PropTypes.number,
  onChangeWidgetType: PropTypes.func,
}

BtuWidgetForm.defaultProps = {
  userLocale: null,
  selectedWidget: 0,
  onChangeWidgetType: () => null,
}

export default BtuWidgetForm
