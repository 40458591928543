import React, {Component} from 'react'
import {connect} from "react-redux"
import {setUserLocale, toggleCollapse} from "../../actions/actionCreators"
import {withTranslation} from 'react-i18next'

import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

import logo from '../../assets/img/logo.png'

import $ from 'jquery'

var target = null;
var scrollTop;
class BtuHeader extends Component {

  componentDidMount() {
    this.bindMenuItem()
  }

  bindMenuItem = () => {
    $("a[href*='#'], button[data-target*='#']").bind('click', function(e) {
      e.preventDefault();
      target = $(this).attr("href") ? $(this).attr("href") : $(this).attr("data-target")
      $('html, body').stop().animate({ scrollTop: $(target).offset().top - 50 }, 600)

      return false
    })
  }

  setLocale = (locale) => {
    let {i18n, dispatch} = this.props
    // Set user locale
    i18n.changeLanguage(locale)
    // Dispatch the update action
    dispatch(setUserLocale(locale))

    // Traduction du carrousel ; 10 ms d'attente, afin que le texte soit réellement traduit
    $(function(){
      function translate_carousel(){
        $('.catalogue-carousel').slick('refresh')
      };
      window.setTimeout(translate_carousel, 10 )
   })
  }

  ScrollFunction = () => {
    $(window).scroll(function () {
    scrollTop  = $(this).scrollTop()
    return scrollTop
    }) 
    return scrollTop
  }
  render() {
    let {
      t,
      userLocale,
      isShownCollapse,
      dispatch
    } = this.props
  

    var scrolled = 0;
    scrolled = this.ScrollFunction()
    return (
      <header>
        <Navbar color="light" expand="xl" className={'bg-white fixed-top'}>
          <Container>
            <NavbarBrand href="/" className={'app-logo d-inline-flex align-items-center'}>
              <img src={logo} alt={t('global.brand')} />
              <span className="uppercase">{t('global.brand')}</span>
            </NavbarBrand>
            <NavbarToggler
              onClick={() => dispatch(toggleCollapse())}
              children={<i className={'la la-bars'}></i>}
              name="navbar-toggler"
              aria-label="Navbar Toggler"
            />
            <Collapse isOpen={isShownCollapse} navbar>
              <Nav className="ml-auto d-flex align-items-center" navbar>
                <NavItem>
                  <NavLink href="#benefits" style={ scrolled >= 530 && scrolled <= 1130 ? {textDecoration: 'underline'} : null}>{t('header.benefits')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#catalogue" style={ scrolled >= 1131 && scrolled <= 1668 ? {textDecoration: 'underline'} : null}>{t('header.catalogue')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#faq" className="uppercase" style={ scrolled >= 2590 && scrolled <= 3470 ? {textDecoration: 'underline'} : null}>{t('header.faq')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#contact" style={ scrolled >= 3690 && scrolled <= 4341 ? {textDecoration: 'underline'} : null}>{t('header.contact')}</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#configuration" className={'btn btn-outline-started d-inline-flex align-items-center uppercase'}>
                    {t('global.get_started')}
                  </NavLink>
                </NavItem>

                <NavItem>
                  { userLocale && userLocale.startsWith('en')
                  ?
                    <NavLink
                      // eslint-disable-next-line no-script-url
                      href="javascript:;"
                      className={'btn d-inline-flex align-items-center'}
                      onClick={() => this.setLocale('fr')}
                    >
                      <div className={"flag-icon fr-flag"} />
                    </NavLink>
                  :
                    <NavLink
                      // eslint-disable-next-line no-script-url
                      href="javascript:;"
                      className={'btn d-inline-flex align-items-center'}
                      onClick={() => this.setLocale('en')}
                    >
                      <div className={"flag-icon en-flag"} />
                    </NavLink>
                  }
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    )
  }
}

BtuHeader.propTypes = {}

const mapStateToProps = (state) => {
  return {
    userLocale: state.btu.userLocale,
    isShownCollapse: state.btu.isShownCollapse
  }
}

BtuHeader = connect(mapStateToProps,null)(BtuHeader)
export default withTranslation()(BtuHeader)