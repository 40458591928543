import {
    CHECKBOX_CHANGE,
    HANDLE_FIELDS_VALIDITY,
    PROCESSING, RESET_FORM, SELECT_CHANGE, SEND_MAIL_FAILURE,
    SEND_MAIL_SUCCESS,
    SET_USER_LOCALE, SIGN_UP_FAILURE, SIGN_UP_SUCCESS,
    TEXT_CHANGE,
    TOGGLE_COLLAPSE,
    TOGGLE_MODAL
} from "../actions/actionTypes";
import {combineReducers} from "redux";
import {FORM_CONTACT, FORM_SIGN_UP} from '../utils/constants';


const initialState = {
    userLocale: null,
    isOpenModal: false,
    isShownCollapse: false,
    modalAction: null,
    isLoading: false,
    [FORM_CONTACT]: {
        name: null,
        email: null,
        subject: null,
        message: null,
        isValidFields: null,
        isMailSent: null,
    },
    [FORM_SIGN_UP]: {
        walletAddress: "0x0364bd820b378a0e6237d00fefaf7e98fa5ef940",
        from: "Btu-Direct Website",
        //username: null,
        email: null,
        phoneCode: '+33',
        phoneNumber: null,
        cgu: false,
        isValidFields: null,
        isSignedUp: null,
    }
};


const btuReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_USER_LOCALE:
            return {
                ...state,
                userLocale: action.payload.userLocale
            };

        case TOGGLE_MODAL:
            return {
                ...state,
                isOpenModal: !state.isOpenModal,
                modalAction: action.payload.modalAction
            };

        case TOGGLE_COLLAPSE:
            return {
                ...state,
                isShownCollapse: !state.isShownCollapse
            };

        case TEXT_CHANGE:
            return {
                ...state,
                [action.payload.formName]: {
                    ...state[action.payload.formName],
                    ...action.payload.data
                }
            };

        case CHECKBOX_CHANGE:
            return {
                ...state,
                [action.payload.formName]: {
                    ...state[action.payload.formName],
                    ...action.payload.data
                }
            };

        case SELECT_CHANGE:
            return {
                ...state,
                [action.payload.formName]: {
                    ...state[action.payload.formName],
                    ...action.payload.data
                }
            };

        case HANDLE_FIELDS_VALIDITY:
            return {
                ...state,
                [action.payload.formName]: {
                    ...state[action.payload.formName],
                    ...action.payload.data
                }
            };

        case PROCESSING:
            return {
                ...state,
                isLoading: action.payload.isLoading
            };

        case SEND_MAIL_SUCCESS:
            return {
                ...state,
                [FORM_CONTACT]: {
                    name: null,
                    email: null,
                    subject: null,
                    message: null,
                    isValidFields: null,
                    isMailSent: true
                }
            };

        case SEND_MAIL_FAILURE:
            return {
                ...state,
                [FORM_CONTACT]: {
                    ...state[FORM_CONTACT],
                    isMailSent: false
                }
            };

        case SIGN_UP_SUCCESS:
            return {
                ...state,
                [FORM_SIGN_UP]: {
                    isSignedUp: true
                }
            };

        case SIGN_UP_FAILURE:
            return {
                ...state,
                [FORM_SIGN_UP]: {
                    ...state[FORM_SIGN_UP],
                    isSignedUp: false
                }
            };

        case RESET_FORM:
            return {
                ...state,
                [FORM_SIGN_UP]: {
                    //username: null,
                    email: null,
                    phoneCode: '+33',
                    phoneNumber: null,
                    cgu: true,
                    isValidFields: null,
                    isSignedUp: null,
                }
            };



        default:
            return state;
    }
};


export const rootReducer = combineReducers({
    btu: btuReducer
});