import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, UncontrolledAlert, FormGroup, FormFeedback, Input, Button } from 'reactstrap'

import {PulseLoader} from 'react-spinners'

import {checkField, checkEmail} from '../../utils/utils'

class BtuContact extends Component {
  state = {
    displayErrors: false,
  }

  sendMail = () => {
    const {
      dispatch,
      sendMail,
    } = this.props

    this.setState({ displayErrors: true })

    dispatch(sendMail())
  }

  render() {
    const {
      t,
      isLoading,
      formContact,
      loaderStyle,
      handleChange,
    } = this.props

    const {
      displayErrors,
    } = this.state

    return (
      <section id="contact">
        <Container>
          <Row>
            <Col>
              <h1 className={'section-title text-center'}>
                {t('contact.title')}
              </h1>

              <div className="form-question pt-5 p-sm-5">
                { !isLoading && formContact && formContact.isMailSent
                  && (
                    formContact.isMailSent === true
                    ? (
                      <UncontrolledAlert color="success" fade={false}>
                        {t('contact.messages.success')}
                      </UncontrolledAlert>
                      )
                    : (
                      <UncontrolledAlert color="danger" fade={false}>
                        {t('contact.messages.error')}
                      </UncontrolledAlert>
                    )
                  )
                }

                <form action="">
                  <Row>
                    <Col md={6}>
                      <FormGroup className={'mb-4'}>
                        <Input
                          type="text"
                          name="name"
                          title={t('contact.form.name_placeholder')}
                          placeholder={t('contact.form.name_placeholder')}
                          onChange={handleChange}
                          value={formContact && formContact.name ? formContact.name : ''}
                          invalid={formContact && displayErrors && !checkField(formContact.name)}
                        />
                        <FormFeedback>
                          {t('global.required')}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className={'mb-4'}>
                        <Input
                          type={'email'}
                          name={'email'}
                          title={t('contact.form.email_placeholder')}
                          placeholder={t('contact.form.email_placeholder')}
                          onChange={handleChange}
                          value={formContact && formContact.email ? formContact.email : ''}
                          invalid={formContact && displayErrors && !checkEmail(formContact.email)}
                        />
                        <FormFeedback>
                          { checkField(formContact.email)
                          ? t('global.invalid')
                          : t('global.required')
                          }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup row className={'mb-4'}>
                    <Col>
                      <Input
                        type={'text'}
                        name={'subject'}
                        title={t('contact.form.subject_placeholder')}
                        placeholder={t('contact.form.subject_placeholder')}
                        onChange={handleChange}
                        value={formContact && formContact.subject ? formContact.subject : ''}
                        invalid={formContact && displayErrors && !checkField(formContact.subject)}
                      />
                      <FormFeedback>
                        {t('global.required')}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row className={'mb-4'}>
                    <Col>
                      <Input
                        type={'textarea'}
                        name={'message'}
                        title={t('contact.form.message_placeholder')}
                        placeholder={t('contact.form.message_placeholder')}
                        onChange={handleChange}
                        value={formContact && formContact.message ? formContact.message : ''}
                        invalid={formContact && displayErrors && !checkField(formContact.message)}
                      />
                      <FormFeedback>
                        {t('global.required')}
                      </FormFeedback>
                    </Col>
                  </FormGroup>

                  <FormGroup row className={'text-center mt-5'}>
                    <Col>
                      <Button
                        className={'btn btn-primary d-inline-flex align-items-center justify-content-between'}
                        onClick={this.sendMail}
                        disabled={isLoading}
                      >
                        {isLoading
                        ? (
                          <React.Fragment>
                            <span className="uppercase">{t('contact.messages.progress')}</span>
                            <PulseLoader
                              css={loaderStyle}
                              sizeUnit={"px"}
                              size={12}
                              color={'#fff'}
                              loading={true}
                            />
                          </React.Fragment>
                          )
                        : (
                          <span className="upppercase">{t('contact.form.button')}</span>
                          )
                        }
                      </Button>
                    </Col>
                  </FormGroup>
                </form>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

BtuContact.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
}

BtuContact.defaultProps = { }

export default BtuContact
