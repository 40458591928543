import React, {Component} from 'react'

import QuestionIcon from '../../assets/icons/interrogation.svg'

class BtuQuestion extends Component {
  state = {
    isHidden: true,
  }

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  onMouseOver = () => {
    this.setState({ isHidden: false })
  }

  onMouseOut = () => {
    this.setState({ isHidden: true })
  }

  render() {
    const {
      text,
      alt,
    } = this.props

    const {
      isHidden,
    } = this.state

    return (
      <div className="question_wrapper">
        <img src={QuestionIcon} alt={alt} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} />
        <div className={'question_tooltip ' + (isHidden ? 'question_tooltip_hidden': '')}>
          {text}
        </div>
      </div>
    )
  }
}

export default BtuQuestion
