export function getBrowserLanguage() {
  let lang = navigator.language || navigator.userLanguage;

  return lang.indexOf('en') >= 0 ? 'en' : 'fr';
}

export function checkField(field) {
  return field && field.trim().length > 0;
}

export function checkEmail(field) {
  // eslint-disable-next-line no-useless-escape
  var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(String(field).toLowerCase())
}