import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'

import frPhone from '../../assets/img/laptop_widget_fr.png'
import enPhone from '../../assets/img/laptop_widget_en.png'
import webToken from '../../assets/icons/icone_page_web_token.png'
import iconMoney from '../../assets/icons/icone_argent.png'
import iconHand from '../../assets/icons/icone_pouce.png'

import ArrowDown from '../../components/arrowDown/arrowDown.jsx'
/**
 * Section "Avantages"
 */
class BtuBenefits extends Component {
  render() {
    const {
      t,
      userLocale,
    } = this.props

    // Liste des images (et des bénéfices à afficher)
    const benefitsImages = [
      webToken,
      iconHand,
      iconMoney,
    ]

    return (
      <section id="benefits">
        <Container>
          <Row>
            <Col lg={6}>
              <h1 className={'section-title wow slideInUp text-center text-lg-left'}
                data-wow-duration="0.5s"
                data-wow-delay="0.1s"
              >
                {t('benefits.title')}

                
              </h1>

              <div>
                <ul className={'list-benefits'}>
                  { t('benefits.points', { returnObjects: true }).map((point, index) => 
                    (
                      <li
                        key={point.title}
                        className={'wow slideInUp'}
                        data-wow-duration="0.5s"
                        data-wow-delay="0.2s">
                        { index < benefitsImages.length
                          && (
                            <span>
                              <img src={benefitsImages[index]} alt={t('benefits.points.' + index + '.title')} />
                            </span>
                          )
                        }
                        <span>
                          <span className={'purple-title'}>
                            {t('benefits.points.' + index + '.text')}
                        </span>
                        </span>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </Col>
            <Col lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className={'mobile-logo text-center'}>
                <img src={ userLocale && userLocale.startsWith('en') ? enPhone : frPhone} alt={t('benefits.title')}
                  className='img-fluid'/>
              </div>
            </Col>
          </Row>
          <ArrowDown
          ancreValue = '1' 
          />
        </Container>
      </section>
    )
  }
}

BtuBenefits.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
  userLocale: PropTypes.string,
}

BtuBenefits.defaultProps = {
  userLocale: null,
}

export default BtuBenefits
