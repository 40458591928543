import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'

import ArrowDown from '../../components/arrowDown/arrowDown.jsx'
/**
 * Section "FAQ"
 */
class BtuFaq extends Component {
  render() {
    const {
      t,
    } = this.props

    return (
      <section id="faq">
        <Container>
          <Row>
            <Col>
              <h1 className={'section-title text-center uppercase'}>
                  {t('faq.title')}
              </h1>

              <div className="faq-questions">
                <Row>
                  { t('faq.sections', { returnObjects: true }).map(section => (
                    <Col
                      key={section.title}
                      md={6}
                    >
                      <Card
                        className={'mb-4 wow slideInUp'}
                        data-wow-duration="0.5s"
                        data-wow-delay="0.1s"
                      >
                        <CardBody>
                          <h3 className={'faq-question'}>
                            {section.title}
                          </h3>
                          <p className={'m-0 faq-description'}>
                            {section.text}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
          <ArrowDown
          ancreValue = '3' 
          />
        </Container>
      </section>
    )
  }
}

BtuFaq.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
}

BtuFaq.defaultProps = {}

export default BtuFaq
