import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from "reactstrap"

import constants from '../../config/constants'

import facebookIcon from '../../assets/icons/facebook.png'
import twitterIcon from '../../assets/icons/twitter.png'
import linkedInIcon from '../../assets/icons/linkedin.png'
import mediumIcon from '../../assets/icons/medium.png'
import telegramIcon from '../../assets/icons/telegram.png'
import youtubeIcon from '../../assets/icons/youtube.png'
import instagramIcon from '../../assets/icons/instagram.png'
import logo from '../../assets/icons/logo.png'
import marker from '../../assets/icons/marker.png'
import email from '../../assets/icons/email.png'

class BtuFooter extends Component {
  render() {
    const {
      t
    } = this.props
    
    return (
      <footer>
        <Container>
          <Row>
            <Col lg={4} className={'mb-5 mb-lg-0'}>
              <h4 className={'f-title d-inline-flex align-items-center'}>
                <img src={logo} alt={t('footer.images.brand')} className={'img-fluid mr-3'}/>
                <span className="uppercase">{t('global.brand')}</span>
              </h4>
              <p className={'f-about'}>
                {t('footer.description.about')}
              </p>

              <ul className="social-links">
                <li>
                  <a href={constants.url.facebook} target={'_blank'} rel="noopener noreferrer">
                    <img src={facebookIcon} alt={t('footer.images.facebook')} />
                  </a>
                </li>
                <li>
                  <a href={constants.url.twitter} target={'_blank'} rel="noopener noreferrer">
                    <img src={twitterIcon} alt={t('footer.images.twitter')} />
                  </a>
                </li>
                <li>
                  <a href={constants.url.linkedIn} target={'_blank'} rel="noopener noreferrer">
                    <img src={linkedInIcon} alt={t('footer.images.linkedin')} />
                  </a>
                </li>
                <li>
                  <a href={constants.url.telegram} target={'_blank'} rel="noopener noreferrer">
                    <img src={telegramIcon} alt={t('footer.images.telegram')} />
                  </a>
                </li>
                <li>
                  <a href={constants.url.medium} target={'_blank'} rel="noopener noreferrer">
                    <img src={mediumIcon} alt={t('footer.images.medium')} />
                  </a>
                </li>
                <li>
                  <a href={constants.url.youtube} target={'_blank'} rel="noopener noreferrer">
                    <img src={youtubeIcon} alt={t('footer.images.youtube')} />
                  </a>
                </li>
                <li>
                  <a href={constants.url.instagram} target={'_blank'} rel="noopener noreferrer">
                    <img src={instagramIcon} alt={t('footer.images.instagram')} />
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={4} className={'mb-5 mb-lg-0'}>
              <h4 className={'f-title'}>
                {t('footer.about.title')}
              </h4>
              <ul className="list-links">
                <li>
                  <a href={constants.url.btuProtocolAbout}>
                    {t('footer.about.who')}
                  </a>
                </li>
                {/* <li>
                  <a href={constants.url.btuProtocolBuyBtu}>
                    {t('footer.about.buy')}
                  </a>
                </li> */}
              </ul>
            </Col>
            <Col lg={4} className={'mb-5 mb-lg-0'}>
              <h4 className={'f-title'}>
                {t('footer.contact.title')}
              </h4>
                <ul className={'list-contact'}>
                  <li className={'d-inline-flex w-100 mb-3 align-items-start'}>
                    <img src={marker} alt={t('footer.images.address')} />
                    <span className={'ml-3'}>
                      {t('footer.contact.address')}
                      <span className={'d-block mt-2'}>
                        {t('footer.contact.rcs')}
                      </span>
                    </span>
                  </li>

                  <li className={'d-inline-flex w-100 align-items-center'}>
                    <img src={email} alt={t('footer.images.email')} />
                    <span className={'ml-3'}>
                      <a href={'mailto:' + constants.url.mail}>{constants.url.mail}</a>
                    </span>
                  </li>
                </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}

BtuFooter.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
}

BtuFooter.defaultProps = { }

export default BtuFooter