import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import iconReview from '../../assets/icons/quotation-marks.png'
/**
 * Section "Reviews"
 */
class BtuReviews extends Component {
  render() {
    const {
      t,
    } = this.props

    // Indice des citations

    const quotesIndex = [0, 1, 2]

    
    return (
      <section id="reviews">
        <Container>
          <Row>
            <Col>
              <div className="review-carousel">
                
                { quotesIndex.map((quoteIndex) => (
                  
                  <div
                    key={quoteIndex}

                    className="review-cell"
                  >
                    <Card>
                      <CardBody>
                        <div className='d-flex align-items-start'>
                          <img src={iconReview} className={'review-icon img-fluid'} alt={t('reviews.quote')} />
                          <div className="card-text pl-4">
                            
                            <p className={'mb-3'}>
                              {t('reviews.quotes.' + quoteIndex + '.text')}
                            </p>
                            <span className={'review-username'}>
                              {'- '}
                              {t('reviews.quotes.' + quoteIndex + '.name')}
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

BtuReviews.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
}

BtuReviews.defaultProps = {}

export default BtuReviews
