import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'

import ArrowDown from '../../components/arrowDown/arrowDown.jsx'

class BtuIntro extends Component {
  render() {
    const {
      t,
    } = this.props

    return (
      <section id="home">
        <Container>
          <Row>
            <Col lg={{size: 7}}>
              <div className="home d-flex align-items-start align-items-xl-center">
                <div className={'p-xl-0 p-3'}>
                  <h1 className='home-title wow slideInUp wow slideInUp'
                    data-wow-duration="0.5s"
                    data-wow-delay="0.1s">
                    {t('intro.monetize')}
                  </h1>
                  <p className={'home-description mb-4 wow slideInUp'}
                    data-wow-duration="0.5s"
                    data-wow-delay="0.2s"
                  >
                    {t('intro.commission')}
                  </p>
                  <button
                    data-target={'#configuration'}
                    className={'btn btn-primary btn-lg btn-get-started font-weight-bold wow slideInUp uppercase'}
                    data-wow-duration="0.5s"
                    data-wow-delay="0.3s"
                  >
                    {t('global.get_started')}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          <ArrowDown
          ancreValue = '0' 
          />
        </Container>
      </section>
    )
  }
}

BtuIntro.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
}

BtuIntro.defaultProps = {}

export default BtuIntro
