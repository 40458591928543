import React, {Component} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom"

import BtuHome from "../screens/BtuHome"

// Redirect to /
class RedirectHome extends React.Component {
  render() {
    this.props.history.push('/')
    return (null)
  }
}

class BtuRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <BtuHome useSuspense={false}/>}/>
          <Route exact path="/*" component={withRouter(RedirectHome)} />
        </Switch>
      </Router>
    )
  }
}

export default BtuRouter