import React, {Component} from 'react'
import PropTypes from 'prop-types'

import CopyIcon from '../../assets/icons/icone_copy_paste.svg'
import BtuQuestion from './question'

import constants from '../../config/constants.js'

class BtuWidgetFormRightPanel extends Component {
  state = {
    codeCopied: false,
    linkCopied: false,
  }

  copy = (text) => {
    const el = document.createElement('textarea')
    el.value = text
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  copyCode = () => {
    const {
      code,
    } = this.props

    this.copy(code)
    this.setState({ codeCopied: true })
  }

  copyLink = () => {
    const {
      link,
    } = this.props

    this.copy(link)
    this.setState({ linkCopied: true })
  }

  render() {
    const {
      t,
      code,
      link,
      selected,
      language,
      isFlipped,
    } = this.props

    const {
      codeCopied,
      linkCopied,
    } = this.state

    let iFrameSrc = ''
    switch (selected) {
      case 0:
        iFrameSrc = constants.url.btuHotels + '/?hl=' + language
        break
      case 1:
        iFrameSrc = constants.url.carRental + '/?hl=' + language
        break
      default:
    }

    return (
      <div className="widget_right">
        <div className="widget_right_front">
          <label>
            {t('configuration.widget.preview')}
          </label>
          <iframe
            title="BTU Earn Preview Iframe"
            width={800}
            height={500}
            src={iFrameSrc}
            frameBorder="0"
            scrolling="no"
          />
        </div>
        <div className="widget_right_back">
        <div className="form_section">
          { isFlipped
            && (
              <img height="1" width="1" style={{display:'none'}} alt="" src="https://px.ads.linkedin.com/collect/?pid=1609017&conversionId=1483194&fmt=gif" />
            )
          }
          <div className="label_with_question">
            <label>
              {t('configuration.widget.code')}
            </label>
            <BtuQuestion
              text={t('configuration.widget.code_tooltip')}
              alt={t('global.help')}
            />
          </div>
            <div className="widget_textarea_wrapper">
              {code}
              <div
                className="widget_textarea_button"
                onClick={this.copyCode}
              >
                <img src={CopyIcon} alt={t('global.copy')} />
              </div>
            </div>
            { codeCopied
              && (
                <div>
                  {t('configuration.widget.code_copied')}
                </div>
              )
            }
          </div>
          <div className="form_section">
            <div className="label_with_question">
              <label>
                {t('configuration.widget.link')}
              </label>
              <BtuQuestion
                text={t('configuration.widget.link_tooltip')}
                alt={t('global.help')}
              />
            </div>
            <div className="widget_textarea_wrapper">
              {link}
              <div
                className="widget_textarea_button"
                onClick={this.copyLink}
              >
                <img src={CopyIcon} alt={t('global.copy')} />
              </div>
            </div>
            { linkCopied
              && (
                <div>
                  {t('configuration.widget.link_copied')}
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

BtuWidgetFormRightPanel.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
  selected: PropTypes.number,
  code: PropTypes.string,
  link: PropTypes.string,
  isFlipped: PropTypes.bool,
}

BtuWidgetFormRightPanel.defaultProps = {
  selected: 0,
  code: null,
  link: null,
  isFlipped: false,
}

export default BtuWidgetFormRightPanel
