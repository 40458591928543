export const SET_USER_LOCALE = 'SET_USER_LOCALE';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE';
export const TEXT_CHANGE = 'TEXT_CHANGE';
export const CHECKBOX_CHANGE = 'CHECKBOX_CHANGE';
export const SELECT_CHANGE = 'SELECT_CHANGE';
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS';
export const SEND_MAIL_FAILURE = 'SEND_MAIL_FAILURE';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';
export const PROCESSING = 'PROCESSING';
export const RESET_FORM = 'RESET_FORM';
export const HANDLE_FIELDS_VALIDITY = 'HANDLE_FIELDS_VALIDITY';
