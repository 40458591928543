import React from 'react'
import { translate } from 'react-multi-lang'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import './arrowDown.css'

const styles = {
  
}

class ArrowDown extends React.Component {
  state = { }

  render() {
    const {
      ancreValue,
    } = this.props

    var index = ["#benefits", "#catalogue", "#faq", "#contact"]
    //const { } = this.props

    //const { } = this.state

    return (
      <div className="demo">
        <a href={index[ancreValue]} style={ ancreValue <= 0 ? {marginTop: '-200%', marginBottom: '9%'} : null}>
          <span />
        </a>
      </div>
    )
  }
}

ArrowDown.propTypes = {
  t: PropTypes.instanceOf(Object).isRequired,
}

ArrowDown.defaultProps = { }

export default withStyles(styles)(translate(ArrowDown))
